/* .App {
  font-family: sans-serif;
  text-align: center;


} */

.ms-Fabric {
  margin: 0 !important;
}
@page {
  size: a4;
  margin: .3in;
}

@media print {

  .col-md-1,.col-md-2,.col-md-3,.col-md-4,
  .col-md-5,.col-md-6,.col-md-7,.col-md-8, 
  .col-md-9,.col-md-10,.col-md-11,.col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8%;
  }
  .col-md-2 {
    width: 16%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33%;
  }
  .col-md-5 {
    width: 42%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58%;
  }
  .col-md-8 {
    width: 66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83%;
  }
  .col-md-11 {
    width: 92%;
  }
  .col-md-12 {
    width: 100%;
  }
  table.report-container {
    page-break-after: always;
  }

  thead.report-header {
    display: table-header-group;
  }
table.table-bordered{
  border: 1px solid #000;
}
  tfoot.report-footer {
    display: table-footer-group;
  }

  .borderTable {
    border: 1px solid #000;
    text-align: center;
  }


  tr {
    width: "100%";
    border: 1px solid #000;
  }

  th,
  td {

    border: 1px solid #000;
    text-align: justify;
  }

  th.tituloFormulario {
    text-align: center;
    border-bottom: 1px solid #000;
  }

  .classCabecera {
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    text-align: center;
  }

  .alineacionTexto {
    text-align: left;
    font-size: 11px;
  }
  .NegritaCampo{
    font-weight: bold;
  }
}